import React from "react";
import { HashLink } from 'react-router-hash-link';
import ProjLitReview from "./ProjLitReview";
import ProjGrok from "./ProjGrok";
import ProjCS from "./ProjCS";
import ProjNeuroCar from "./ProjNeuroCar";
import ProjSNN from "./ProjSNN";

function Projects() {
    
    return (
        <div className="mx-5">
            <h1 className="text-center">Projects</h1>

            <p className="text-center">
                <HashLink smooth to="#grok">Safety and Interpretability: Progress measures for grokking tickets</HashLink> | <HashLink smooth to="#cs6101">A Survey of Intelligent Autonomous Agents</HashLink> | <HashLink smooth to="#neurocar">Car Detection with Neuromorphic Cameras</HashLink> | <HashLink smooth to="#snn">Spiking Neural Networks</HashLink> | <HashLink smooth to="#litreview">Literature Reviews</HashLink>
            </p>

            <br/><hr id="grok"></hr><br/><br/>
            <ProjGrok/>
            
            <br/><hr id="neurocar"></hr><br/><br/>
            <ProjNeuroCar/>

            <br/><hr id="snn"></hr><br/><br/>
            <ProjSNN/>

            <br/><hr id="cs6101"></hr><br/><br/>
            <ProjCS/>

            <br/><hr id="litreview"></hr><br/><br/>
            <ProjLitReview/>

        </div>
    )
}

export default Projects;