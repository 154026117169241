import React from "react";


function ProjSNN() {
    return (
        <>
            <h2>Spiking Neural Networks</h2>
            Work done while at NUS
            
            <h3>Intro</h3>
            <ul>
                <li>Why: Spiking neural networks (SNNs) are bio-inspired models that enable high speed, low power + data  training and inference</li>
                <li>Problem: SNNs use a different data format (location, polarity, timestamp) as each pixel / neuron is asynchronous, whereas standard computer vision acquires data synchronously in frames. As such, there aren't that many SNN-friendly data sets to unlock the power of SNNs!</li>
                <li>Previous approach: <a target="_blank" href="https://www.frontiersin.org/articles/10.3389/fnins.2016.00405/full">Hu <em>et. al.</em></a> placed a DVS camera (which acquires spikes in the format amenable to SNNs) in front of a monitor to record a spiking version of the <a target="_blank" href="https://www.crcv.ucf.edu/data/UCF50.php">UCF 50</a> activity recognition data set. However, this approach compresses each pixel into a binary format, missing out on color information. Furthermore, this approach is not scalable.</li>
            </ul>
            
            <h3>Contributions</h3>
            <ul>
                <li>Use <a target="_blank" href="https://www.sciencedirect.com/science/article/pii/S0896627301003221">bio-inspired models</a> to convert normal videos to spikes (for each of the RGB channels) for SNN training, unlocking large data sets for the field in a scalable way.</li>
                <li>Updated <a target="_blank" href="https://github.com/bamsumit/slayerPytorch">slayerPytorch</a>, a PyTorch implementation of the <a target="_blank" href="https://arxiv.org/abs/1810.08646">SLAYER</a> algorithm for training SNNs, to handle RGB channels in image and video (data processing, animating, test cases), when previously it handled only a single channel due to prior data sets.</li>
                <li>Compared our approach with Hu <em>et. al.</em> in terms of data requirements and accuracy on SNNs, demonstrated the low data advantage of using SNNs and spiking data</li>
            </ul>

            <h3>Visualization</h3>
            <img className="img-fluid w-100 mt-3" src={require("../img/og_data.gif")} alt="og_data"/>
            Left: Original video from UCF 50. Right: Converted video spikes via DVS camera by Hu <em>et. al.</em>. 
            <img className="img-fluid w-100 mt-3" src={require("../img/converted_spikes.gif")} alt="converted_spikes"/>
            Our approach: 3 different ways to convert videos to spikes, preserving RGB information
            <h3>Results</h3>
            Hu <em>et. al.</em>'s approach compresses data to &lt; 1% of original size and achieves ~60% accuracy when the data is trained on an SNN while our approach compresses data to &lt; 8% of original size and achieving ~70% accuracy when trained on an SNN. This is consistent with the fact that while both approaches sparsify the data, our approach retains more information (RGB channels) and thus is slightly larger in size, but with higher test accuracy.

        </>
    )
}
export default ProjSNN;