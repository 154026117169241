import React from "react";
import { HashLink } from 'react-router-hash-link';
import YoutubeEmbed from "./YoutubeEmbed";

function ProjCS() {
    return (
        <>
            <h2>A Survey of Intelligent Autonomous Agents</h2>
            Below are links to details of the various work done in NUS CS6101 Large Language Models.
            <ul>
                <li><a target="_blank" href="/other/NC_steps_poster.pdf">Poster</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/autonomous-agent-notes">Brief notes on intelligent agents and benchmarks</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/agi-potential-notes">Brief notes on current techniques</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/webarena-experiment-notes">WebArena Experiments</a></li>
                <li><HashLink smooth to="#grok">Safety and Interpretability: Progress measures for grokking tickets</HashLink></li>
            </ul>

            <b>Live presentation: Towards Reasoning Systems with LLMs </b>

            (1:17:44 mark)

            <YoutubeEmbed link="https://www.youtube.com/embed/nZC_xXIgVBo?start=4663&end=6787" />
        </>
    )
}
export default ProjCS;