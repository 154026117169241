import React from "react";

function Publication(props) {
    return (
        <div className="row align-items-center my-3" id={props.id}>
            {props.imgName && 
                <div className="col col-md-2">
                    <img className="img-fluid" src={require(`../img/${props.imgName}`)} />
                </div>
            }
            <div className="col-md">
                {props.authors}{', '}
                <strong>"{props.title}"</strong>{', '}
                {props.details}{'. '}
                {props.links.map(([link, txt]) => <>[<a target="_blank" href={link}>{txt}</a>] </>)}
            </div>
        </div>
    )
}
export default Publication;