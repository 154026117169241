import React from "react";

function Ideation() {
    

    return (
        <div className="mx-5">
            <h1 className="text-center">Ideation</h1>
            Here are some random ideas that can be beneficial to the field (and to someone looking for something to work on!). I have too many todos to give them my full attention, so I'd rather someone else make progress on it. If you're interested in collaborating, please fill in <a target="_blank" href="https://airtable.com/appKURvMr9F7TBw3L/shr1LXXndC5TywnlC">this form</a>
            
            <br/><hr></hr><br/>
            
            <h2>Graph ML</h2>
            <ul>
                <li>Distant supervision: Can we go beyond binary features and use more general graph features (eg shortest path between articles)? Ref: <a target="_blank" href="https://arxiv.org/abs/2203.15827">LinkBERT</a>, <a target="_blank" href="https://arxiv.org/abs/2109.04912">ReasonBERT</a></li>
            </ul>

            <h2>Mechanistic Interpretability</h2>
            <ul>
                <li>
                    If we give a trained model access to a tool and we keep training it, might the neurons that atrophy be the circuit that implements the tool?
                </li>
                <li>
                    ICL and induction heads: Are characteristics (eg progress measures, loss curves) similar between the smeared key model and a grokking ticket, and if so, what can we say about the lottery ticket hypothesis and the connection to structures etc?
                </li>
                <li>
                    We observe double descent on grokking tickets for modulo addition. Does the magnitude vary with the amount of weight decay?
                </li>
            </ul>

            <h2>Cognition</h2>
            Generative agents upgrades
            <ul>
                <li>
                    Currently the retrieved events are responded to randomly. Is there a better way? Some form of attention mechanism?
                </li>
                <li>
                    Many of the processes require calls to LMs. Can we substitute them for something else? Symbolic logic if appropriate?
                </li>
            </ul>

        </div>
    )
}

export default Ideation;