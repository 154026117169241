import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";
import Carousel from 'react-bootstrap/Carousel';

function ProjNeuroCar() {
    return (
        <>
            <h2>Car Detection with Neuromorphic Cameras</h2>

            <h3>Intro</h3>
            <ul>
                <li>What: Neuromorphic Cameras / Dynamic Vision Sensors (DVS) are retina-inspired cameras which captures <strong>changes</strong>.</li>
                <li>Why: High speed (overcomes motion blurring), high dynamic range (overcomes extreme illumination), energy and data efficient -- suitable for autonomous vehicles, drones!</li>
                <li>Problem: Labelled data in real-world conditions is not readily available.</li>
            </ul>

            <h3>Contributions</h3>
            <ul>
                <li>Used pseudo-labels (due to lack of real labels) to train a neural network for car detection at 100 FPS, 40.3% AP, despite motion blur and poor illumination</li>
                <li>Showed that DVS detection <strong>complements</strong> existing frame-based CNNs</li>
            </ul>

            <h3>Visualization</h3>
            <h4>Animated examples</h4>
            <p>
                Left: DVS video with bounding boxes produced by DVS-only detection. Middle: APS (frame-based) video with DVS-only bounding boxes copied over for convenience. Right: Frame-based CNN detections for comparison.
            </p>
            <img className="img-fluid w-100 mt-3" src={require("../img/motionBlur.gif")} alt="motionBlurGif"/>
            During a sharp turn, the car on the frame-based camera (right panel) is blurred and the frame-based CNN fails to detect it, while the DVS (left panel) manages to capture its features well enough to be detected

            <img className="img-fluid w-100 mt-3" src={require("../img/nightDet.gif")} alt="nightDet"/>
            Under poor lighting conditions, the frame based camera (right panel) struggles to capture and detect cars parked at the left lane, while the DVS detector (left panel) still works. 

            <h4>More examples</h4>
            <p>
                Left: DVS image with <strong style={{color: "red"}}>red</strong> bounding boxes produced by DVS-only detection. Right: APS (frame-based) image with DVS-only <strong style={{color: "red"}}>red</strong> bounding boxes copied over and CNN detections (in <strong style={{color: "yellow"}}>yellow</strong>) for comparison.
            </p>

            <img className="img-fluid w-100 mt-3" src={require("../img/blurLighting.png")} alt="blurLighting"/>
            Despite dim lighting and motion blur, the edges of the car are still visible on the DVS and hence is detected by the DVS-only detector but not by conventional CNN
            <img className="img-fluid w-100 mt-3" src={require("../img/illum.png")} alt="illum"/>
            Night scene where cars are detected by the DVS but not by conventional camera
            <img className="img-fluid w-100 mt-3" src={require("../img/motionBlur.png")} alt="motionBlur"/>
            DVS-only detector detects a car despite motion blur, but the conventional CNN fails to do so.
            <img className="img-fluid w-100 mt-3" src={require("../img/stationary.png")} alt="stationary"/>
            DVS fails to detect a stationary car as it depends on changes.
            <img className="img-fluid w-100 mt-3" src={require("../img/faraway.png")} alt="faraway"/>
            Example where a car in the far-field does not trigger a response in the DVS

            <br/>
            <h5>Sample test video</h5>
            Left: Output from the dynamic vision sensor, with bounding boxes produced by the DVS-only detector. Middle: APS (grayscale) sensor image, with bounding boxes copied from DVS-only detection for comparison. Right: APS sensor image with RRC detections for comparison.
            <YoutubeEmbed link="https://www.youtube.com/embed/6QHP7xhcYx0" />

            <h3>More Info</h3>
            N. <strong>Chen</strong>, <strong>“Pseudo-labels for Supervised Learning on Dynamic Vision Sensor Data, Applied to Object Detection under Ego-motion”</strong>, The IEEE Conference on Computer Vision and Pattern Recognition (CVPR) Embedded Vision Workshop, 18-22 June 2018 (Poster Session). 
            [<a target="_blank" href="https://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w12/Chen_Pseudo-Labels_for_Supervised_CVPR_2018_paper.pdf">Paper</a>]
            [<a target="_blank" href="https://openaccess.thecvf.com/content_cvpr_2018_workshops/supplemental/Chen_Pseudo-Labels_for_Supervised_CVPR_2018_supplemental.pdf">Supplemental</a>]
            [<a target="_blank" href="https://openaccess.thecvf.com/CVPR2018_workshops/CVPR2018_W12">Workshop</a>]



            {/* <Carousel variant="">

                <Carousel.Item>
                    <img className="img-fluid w-100 mt-3" src={require("../img/faraway.png")} alt="faraway"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="img-fluid w-80 mt-3" src={require("../img/faraway.png")} alt="faraway"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="img-fluid w-80 mt-3" src={require("../img/faraway.png")} alt="faraway"/>
                </Carousel.Item>

            </Carousel> */}
        </>
    )
}
export default ProjNeuroCar;