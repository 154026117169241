import React from "react";
import { Route, Routes } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import About from "./components/About";
import Projects from "./components/Projects";
import Talks from "./components/Talks";
import Ideation from "./components/Ideation";

function App() {
  return (
    <div>
      <NavigationBar />
      <Routes>
          {/* <Route exact path="/" element={<HomePage />} /> */}
          <Route exact path="/" element={<About />} />
          {/* <Route path="/About" element={<About />} />  */}
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Talks" element={<Talks />} />
          <Route path="/Ideation" element={<Ideation />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;