import React from "react";

function Homepage() {
    

    return (
        <div style={{position: "relative", "text-align": "center"}}>
            <img className="img-fluid w-100" style={{opacity: "0.5"}} src={require("../img/home.jpg")} alt="home"/>
            <div style={{position: "absolute", top: "0%", left: "50%", transform: "translate(-50%, -0%)"}}>
                <h1 className="display-1">Hi, I'm Nicholas</h1>
                <h3>Scientist. Developer. Gamer.</h3>
                <h4>AI. Physics. Math</h4>
            </div>
        </div>
    )
}

export default Homepage;