import React from "react";

function Affiliation(props) {
    return (
        <div className="row align-items-center my-3">
            {props.imgName && 
                <div className="col col-md-2">
                    <img className="img-fluid" src={require(`../img/${props.imgName}`)} />
                </div>
            }
            <div className="col-md">
                <strong>{props.title}</strong><br />
                {props.subtitles.map((subtitle) => <><i>{subtitle}</i><br /></>)}
                <ul>{props.points.map((point) => <li>{point}</li>)}</ul>
            </div>
        </div>
    )
}
export default Affiliation;