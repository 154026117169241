import React from "react";

function Footer() {
  const year = new Date().getFullYear();
  return (
    // <footer style={{backgroundColor: "#000000"}}>
    <footer>
      <div className="m-5" >
        Copyright ⓒ {year} Nicholas Chen
      </div>
      
    </footer>
  );
}

export default Footer;
