import React from "react";

function ProjLitReview() {
    return (
        <>
            <h2>Literature Reviews</h2>
            <ul>
                <li><a target="_blank" href="https://github.com/nicholaschenai/AI_for_code_brief_notes">AI for code</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/interp-notes">Interpretability</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/autonomous-agent-notes">Intelligent Agents and Benchmarks</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/agi-potential-notes">Understanding Intelligence</a></li>
            </ul>
        </>
    )
}
export default ProjLitReview;