import React from "react";
import { HashLink } from 'react-router-hash-link';
import Affiliation from "./Affiliation";
import Publication from "./Publication";
import YoutubeEmbed from "./YoutubeEmbed";

function Talks() {
    

    return (
        <div className="mx-5">
            <h1 className="text-center">Talks</h1>

            <br/><hr></hr><br/>
            <h1>LLMs + Cognitive Architecture = Generalist Agents? </h1>
            MLSG meetup, Google SG, 20240220.
            
            <ul>
                <li><a target="_blank" href="/other/20240220-mlsg-ca-final.pptx">Slides</a></li>
                <li><a target="_blank" href="https://github.com/nicholaschenai/agi-potential-notes">Notes on papers discussed during the talk</a></li>
                <li><a target="_blank" href="https://www.youtube.com/@amusementintelligence">CoALA in-depth video</a></li>
                <li><HashLink smooth to="#reasoning-systems">Voyager & Gen Agents video</HashLink></li>
            </ul>

            <br/><hr id="reasoning-systems"></hr><br/>
            <h1>Towards Reasoning Systems with LLMs </h1>
            NUS, 20231013.

            (1:17:44 mark)
            <YoutubeEmbed link="https://www.youtube.com/embed/nZC_xXIgVBo?start=4663&end=6787" />
            
        </div>
    )
}

export default Talks;